<template>
  <!-- v-loading="loading" -->
  <div class="bg-white box-shadow border-r px-20 pb-20" v-loading="loading">
    <div class="d-flex-center px-15 border-bottom">
      <h4 class="mr-auto color-333">{{ $t("account.contract") }}</h4>
      <el-button
        type="primary"
        size="mini"
        icon="el-icon-refresh"
        plain
        @click="fatchRecord()"
        >{{ $t("base.refresh") }}</el-button
      >
    </div>

    <div class="my-contract">
      <div class="my-contract-item">
        <strong
              :class="
        Number(userContract.net_worth) > 0
          ? 'color-s'
          : Number(userContract.net_worth) < 0
          ? 'color-d'
          : ''
      "
        >{{userContract.net_worth}}</strong>
        <div>{{$t('contact_about.top_text_h1')}}</div>
      </div>
      <div class="my-contract-item">
        <strong>{{userContract.cash_deposit_total}}</strong>
        <div>{{$t('contact_about.top_text_h2')}}</div>
      </div>
      <div class="my-contract-item">
        <strong
                              :class="
                        Number(userContract.income) > 0
                          ? 'color-s'
                          : Number(userContract.income) < 0
                          ? 'color-d'
                          : ''
                      "
        >{{userContract.income}}</strong>
        <div>{{$t('contact_about.top_text_h3')}}</div>
      </div>
      <div class="my-contract-item">
        <strong>{{userContract.balance}}</strong>
        <div>{{$t('contact_about.available')}}</div>
      </div>
    </div>

    <div class="select-zoom">
      <div class="select-item">
        <el-select
          v-model="select1"
          :placeholder="$t('user_contract.placeholder1')"
          class="th-select"
          size="medium"
          style="width: 150px"
          @change="selectFn1"
        >
          <el-option
            v-for="(item, index) in options1"
            :key="index"
            :label="item.pair"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="select-item">
        <el-select
          v-model="select2"
          :placeholder="$t('user_contract.placeholder1')"
          class="th-select"
          size="medium"
          style="width: 150px"
          @change="selectFn2"
        >
          <el-option
            v-for="(item, index) in options2"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="select-item">
        <el-select
          v-model="select3"
          :placeholder="$t('user_contract.placeholder1')"
          class="th-select"
          size="medium"
          style="width: 150px"
          @change="selectFn3"
        >
          <el-option
            v-for="(item, index) in options3"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 新列表 -->
    <div class="list-outline">
      <el-row
        :gutter="20"
        type="flex"
        justify="center"
        align="middle"
        class="color-gray py-15 border-bottom font-14"
        style="padding-right: 20px; padding-left: 20px"
      >
        <el-col :span="6">{{ $t("contact_about.table1.pair") }}</el-col>
        <el-col :span="4">{{ $t("contact_about.table1.type") }}</el-col>
        <el-col :span="4">{{ $t("contact_about.table1.span_h7") }}</el-col>
        <el-col :span="6">{{ $t("contact_about.table1.income") }}</el-col>
        <el-col :span="6">{{ $t("contact_about.table1.rate_return") }}</el-col>
        <el-col :span="4">{{ $t("contact_about.table1.service_charge") }}</el-col>
        <el-col class="text-right" :span="4">{{
          $t("contact_about.table1.deal")
        }}</el-col>
      </el-row>

      <div class="contactlist-item" v-for="(item, index) in list" :key="index">
        <div
          class="contactlist-item-into"
          @click="item.flag = !item.flag"
          :class="item.flag ? (item.state==3?'contactlist-item-active contactlist-item-active1':'contactlist-item-active') : ''"
        >
          <el-row
            :gutter="20"
            type="flex"
            justify="center"
            align="middle"
            class="py-15 border-bottom font-14"
            style="padding-right: 20px; padding-left: 20px"
          >
            <el-col :span="6">
              <div class="coin-zoom">
                <span class="coin-zoom-icon">
                  <i class="el-icon-caret-bottom"></i>
                </span>
                <div class="coin-zoom-into">
                  <strong
                    >{{ item.pair.toUpperCase() }} /
                    {{ item.coin.toUpperCase() }}</strong
                  >
                  <!-- <span>Buy</span> -->
                </div>
              </div>
            </el-col>
            <el-col :span="4">
              <span :class="item.type ? 'color-d' : 'color-s'">
                {{ item.type ? "Sell" : "Buy" }}
              </span>
            </el-col>
            <el-col :span="4">
              <span>
                {{ item.level + "x" }}
              </span>
            </el-col>
            <el-col :span="6">
              <span
                v-if="item.state == 2 || item.state == 3"
                :class="
                  item.income > 0 && item.state == 2
                    ? 'color-s'
                    : item.income < 0 && item.state == 2
                    ? 'color-d'
                    : ''
                "
              >
                {{ item.income }}
              </span>
              <span v-else>---</span>
            </el-col>
            <el-col :span="6">
              <span
                v-if="item.state == 2 || item.state == 3"
                :class="
                  floatingpoint.bed(
                    Number(item.income),
                    Number(item.cash_deposit)
                  ) < 0 && item.state == 2
                    ? 'color-d'
                    : floatingpoint.bed(
                        Number(item.income),
                        Number(item.cash_deposit)
                      ) > 0 && item.state == 2
                    ? 'color-s'
                    : ''
                "
              >
                {{
                  floatingpoint
                    .mul(
                      floatingpoint.bed(
                        Number(item.income),
                        Number(item.cash_deposit)
                      ),
                      100
                    )
                    .toFixed(2) + "%"
                }}
              </span>
              <span v-else>---</span>
            </el-col>
            <el-col :span="4">
              <span>
                {{item.service_charge}}
              </span>
            </el-col>
            <el-col class="text-right" :span="4">
              <span v-if="item.state == 0">
                <el-button
                  type="danger"
                  size="small"
                  plain
                  class="bg-t"
                  @click.stop="cancelFn(item.id)"
                  >{{ $t("user_contract.span1") }}</el-button
                >
              </span>
              <span v-if="item.state == 1" class="color-d">{{
                $t("user_contract.span2")
              }}</span>
              <span v-if="item.state == 2">
                <el-button
                  type="primary"
                  size="small"
                  @click.stop="cleanFn(item.id)"
                  style="padding: 9px 8px;box-sizing: border-box;"
                  >{{ $t("contact_about.liquidation") }}</el-button
                >
              </span>
              <span v-if="item.state == 3">{{
                $t("user_contract.span4")
              }}</span>
              <span v-if="item.state == 4" class="color-d">{{
                $t("user_contract.span5")
              }}</span>
            </el-col>
          </el-row>
          <div class="contactlist-item-detail">
            <div class="contactlist-item-detail-into">
              <div class="detail-row">
                <div class="detail-col">
                  <span class="span-h">{{
                    $t("contact_about.table1.span_h1")
                  }}</span>
                  <span class="span-p">{{ Number(item.entrust_price) }}</span>
                </div>
                <div class="detail-col">
                  <span class="span-h">{{
                    $t("contact_about.table1.span_h2")
                  }}</span>
                  <span class="span-p">{{ Number(item.open_price) }}</span>
                </div>
                <div class="detail-col">
                  <span class="span-h">{{
                    $t("contact_about.table1.span_h3")
                  }}</span>
                  <span class="span-p">{{ Number(item.cash_deposit) }}</span>
                </div>
                <div class="detail-col">
                  <span class="span-h">{{
                    $t("contact_about.table1.span_h4")
                  }}</span>
                  <span class="span-p">{{
                    item.new_price ? Number(item.new_price) : "---"
                  }}</span>
                </div>
              </div>
              <div class="detail-row">
                <div class="detail-col">
                  <span class="span-h">{{
                    $t("contact_about.table1.span_h5")
                  }}</span>
                  <span class="span-p">{{ item.create_time }}</span>
                </div>
                <div class="detail-col">
                  <span class="span-h">{{
                    $t("contact_about.table1.span_h6")
                  }}</span>
                  <span class="span-p">{{
                    item.open_time ? item.open_time : "---"
                  }}</span>
                </div>
                <!-- <div class="detail-col">
                  <span class="span-h">{{
                    $t("contact_about.table1.span_h7")
                  }}</span>
                  <span class="span-p">{{ item.level + "x" }}</span>
                </div> -->
              </div>
              <div class="detail-row" v-if="item.state == 3">
                <div class="detail-col">
                  <span class="span-h">{{
                    $t("contact_about.table1.span_h8")
                  }}</span>
                  <span class="span-p">{{ item.close_price }}</span>
                </div>
                <div class="detail-col">
                  <span class="span-h">{{
                    $t("contact_about.table1.span_h9")
                  }}</span>
                  <span class="span-p">{{ item.close_time }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新列表 -end -->

    <!-- <el-row
      :gutter="20"
      type="flex"
      justify="center"
      align="middle"
      class="market-header px-20 py-15 border-bottom font-14"
    >
      <el-col :span="5">
          币种
      </el-col>
      <el-col :span="4">收益</el-col>
      <el-col :span="4">收益率</el-col>
      <el-col :span="4">委托价</el-col>
      <el-col :span="4">开仓价</el-col>
      <el-col :span="4">最新价</el-col>
      <el-col :span="5">保证金</el-col>
      <el-col :span="5">委托时间</el-col>
      <el-col :span="5">开仓时间</el-col>
      <el-col class="text-right" :span="4">
        状态
      </el-col>
    </el-row>
    
    <el-row
      v-for="(item, index) in list"
      :key="index"
      :gutter="20"
      type="flex"
      justify="center"
      align="middle"
      class="market-header px-20 py-15 border-bottom font-14"
    >
      <el-col :span="5">
          币种
      </el-col>
      <el-col :span="4">收益</el-col>
      <el-col :span="4">收益率</el-col>
      <el-col :span="4">委托价</el-col>
      <el-col :span="4">开仓价</el-col>
      <el-col :span="4">最新价</el-col>
      <el-col :span="5">保证金</el-col>
      <el-col :span="5">委托时间</el-col>
      <el-col :span="5">开仓时间</el-col>
      <el-col class="text-right" :span="4">
        状态
      </el-col>
    </el-row> -->

    <div class="pagination-outline">
      <div v-if="list.length < 1" class="py-50 font-14 color-i text-center">
        No More
      </div>
      <div class="pagination-box" v-else>
        <el-pagination
          layout="prev, pager, next"
          background
          :total="total1"
          :page-size="pageSize"
          :current-page="page"
          @current-change="pageFn1"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      record: {
        data: [],
      },
      state: [
        ["primary", "Trading"],
        ["info", "Cancel"],
        ["success", "Deal"],
      ],
      state_new: [
        this.$t("transactions.state_new1"),
        this.$t("transactions.state_new2"),
        this.$t("transactions.state_new3"),
        this.$t("transactions.state_new4"),
      ],
      list: [],
      page: 1,
      pageSize: 10,
      total1: 0,

      contactList_top:[],
      userContract:{},

      options1: [
        {
          value: 0,
          pair: this.$t("user_contract.rentext1"),
        },
        // {
        //   value: 1,
        //   pair: "BTC",
        // },
        // {
        //   value: 2,
        //   pair: "ETH",
        // },
        // {
        //   value: 3,
        //   pair: "DOGE",
        // },
      ],
      select1: 0,

      options2: [
        {
          value: 0,
          label: this.$t("user_contract.rentext2"),
        },
        {
          value: 1,
          label: this.$t("user_contract.rentext3"),
        },
        {
          value: 2,
          label: this.$t("user_contract.rentext4"),
        },
      ],
      select2: 0,

      options3: [
        {
          value: 0,
          label: this.$t("user_contract.rentext5"),
        },
        {
          value: 1,
          label: this.$t("user_contract.rentext6"),
        },
        {
          value: 2,
          label: this.$t("user_contract.rentext7"),
        },
        {
          value: 3,
          label: this.$t("user_contract.rentext8"),
        },
        {
          value: 4,
          label: this.$t("user_contract.rentext9"),
        },
        {
          value: 5,
          label: this.$t("user_contract.rentext10"),
        },
      ],
      select3: 0,
      socket1: null,
    };
  },
  created: function () {
    // this.fatchRecord()
  },
  mounted: function () {
    this.fatchRecord();
    this.getPair();
    this.fatchContactList1();
    this.fatchUserContract();
  },
  destroyed: function () {
    this.socket1.close(); //离开路由之后断开websocket连接
  },
  methods: {
    //获取动态数据
    getdata_wss: function () {
      if (this.socket1) {
        this.socket1.close(); //离开路由之后断开websocket连接
      }

      //wss實時請求
      // this.socket1 = this.$io("ws://47.243.105.117:9502/symbol", {
      this.socket1 = this.$io(this.$wsn+"/symbol", {
        query: {},
        transports: ["websocket", "polling"],
        timeout: 5000,
        // pingTimeout: 20000,
        // pingInterval: 25000,
      });

      this.socket1.on("connect_error", (reason) => {
        console.log(reason);
      });

      this.socket1.on("connect", (socket) => {
        // ws连接已建立，此时可以进行socket.io的事件监听或者数据发送操作
        console.log("ws 已连接");

        //全部报价
        this.socket1.emit("symbol_price_list");

        this.socket1.on("symbol_price_list", (res) => {
          //   this.market[JSON.parse(res).pair + "" + JSON.parse(res).coin]
          //     ? (this.market[JSON.parse(res).pair + "" + JSON.parse(res).coin] =
          //         JSON.parse(res))
          //     : "";
          //   this.market = JSON.parse(JSON.stringify(this.market));

          //业务逻辑匹配计算持仓订单的收益、收益率，最新报价（动态）
          if (this.list.length > 0) {
            //确认数据量少，直接foreach,后期改成{}优化
            let $that = this;
            this.list.forEach(function (item, index) {
              if (item.pair == JSON.parse(res).pair && item.coin == JSON.parse(res).coin && item.state == 2) {
                item.new_price = JSON.parse(res).lasest_price;
                if (item.type) {
                  // 买跌
                  item.income = $that.floatingpoint
                    .mul(
                      $that.floatingpoint.sub(
                        Number(item.open_price),
                        Number(JSON.parse(res).lasest_price)
                      ),
                      Number(item.amount)
                    )
                    .toFixed(8);
                } else {
                  //买涨
                  item.income = $that.floatingpoint
                    .mul(
                      $that.floatingpoint.sub(
                        Number(JSON.parse(res).lasest_price),
                        Number(item.open_price)
                      ),
                      Number(item.amount)
                    )
                    .toFixed(8);
                }
              }
            });
          }

          //业务逻辑匹配计算持仓订单的收益、收益率，最新报价（动态）//顶部信息
          if (this.contactList_top.length > 0) {
            //确认数据量少，直接foreach,后期改成{}优化
            let $that = this;
            this.contactList_top.forEach(function (item, index) {
              if (item.pair == JSON.parse(res).pair && item.coin == JSON.parse(res).coin && item.state == 2) {
                item.new_price = JSON.parse(res).lasest_price;
                $that.new_price_input = JSON.parse(res).lasest_price;
                if (item.type) {
                  // 买跌
                  item.income = $that.floatingpoint
                    .mul(
                      $that.floatingpoint.sub(
                        Number(item.open_price),
                        Number(JSON.parse(res).lasest_price)
                      ),
                      Number(item.amount)
                    )
                    .toFixed(8);
                } else {
                  //买涨
                  item.income = $that.floatingpoint
                    .mul(
                      $that.floatingpoint.sub(
                        Number(JSON.parse(res).lasest_price),
                        Number(item.open_price)
                      ),
                      Number(item.amount)
                    )
                    .toFixed(8);
                }
              }
            });
            this.incomeFn();
          }

        });
      });

      this.socket1.on("disconnect", (reason) => {
        console.log(reason);
      });

      this.socket1.on("error", (msg) => {
        console.log("ws error", msg);
      });
    },

    //合约订单
    fatchRecord: async function () {
      this.loading = true;
      // const { data } = await this.$request.get('transaction/order_pc/coin/'+this.$route.params.coin)
      const { data } = await this.$request.post("v1/contract/getOrderList", {
        page: this.page,
        pageSize: this.pageSize,
        symbol: this.select1 ? this.select1.toLowerCase() + "usdt" : "",
        type: this.select2 ? Number(this.select2) - 1 : "",
        state: this.select3 ? Number(this.select3) - 1 : "",
      });
      data.list.forEach(function (item, index) {
        item.flag = false;
      });
      this.list = data.list;
      this.total1 = data.total;
      this.loading = false;
      this.getdata_wss();
    },

    //合约订单列表处理顶部信息（不分币种不分类型）
    fatchContactList1: function () {
      this.contactList_top = [];
      this.$request
        .post("v1/contract/getOrderList", {
          page: 1,
          pageSize: 999,
          state: 2,
        })
        .then(({ data }) => {
          let $data = data.list;
          this.contactList_top = $data;
        });
    },

    //获取合约钱包余额及顶部信息
    fatchUserContract: async function () {
      this.userContract = {
        net_worth: "", //净值
        balance: "", //可用余额
        income: "", //持仓收益
        cash_deposit_total: "", //占用保证金
      };
      try {
        const { data } = await this.$request.post(
          "v1/contract/getAccountBalance"
        );
        this.userContract = data;
      } catch (e) {
        //TODO handle the exception
      }
    },

    //顶部信息
    incomeFn: function (e) {
      let $income = 0;
      let $that = this;
      if (this.contactList_top) {
        this.contactList_top.forEach(function (item, index) {
          $income = $that.floatingpoint.add(
            Number($income),
            Number(item.income)
          );
        });
        $that.userContract.income = $income.toFixed(8);
        $that.userContract.net_worth = $that.floatingpoint
          .add(
            $that.floatingpoint.add(
              $that.userContract.income,
              $that.userContract.balance
            ),
            $that.userContract.cash_deposit_total
          )
          .toFixed(8);
      }
    },

    //撤单
    cancelFn: async function (id) {
      try {
        this.loading = true;
        let $data = await this.$request.post("v1/contract/revocation", {
          id: id,
        });
        // this.tradingLoading = false;
        // this.tradingList.splice(index, 1);
        let that = this;
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.total1 = 0;
            that.page = 1;
            that.fatchRecord();
          },
        });
      } catch (error) {
        this.loading = false;
      }
    },

    //平仓
    cleanFn: async function (id) {
      try {
        this.loading = true;
        let $data = await this.$request.post("v1/contract/close", {
          id: id,
        });
        // this.tradingLoading = false;
        // this.tradingList.splice(index, 1);
        let that = this;
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.total1 = 0;
            that.page = 1;
            that.fatchRecord();
          },
        });
      } catch (error) {
        this.loading = false;
      }
    },

    pageFn1: function (e) {
      this.page = e;
      this.fatchRecord();
    },

    //获取币种
    getPair: async function () {
      const { data } = await this.$request.post("v1/symbol/getList", {
        coin: "usdt",
      });
      data.list.forEach(function (item, index) {
        item.pair = item.pair.toUpperCase();
        item.value = item.pair;
      });
      this.options1 = this.options1.concat(data.list);
    },

    //
    selectFn1: function (e) {
      this.fatchRecord();
    },
    //
    selectFn2: function (e) {
      this.fatchRecord();
    },
    //
    selectFn3: function (e) {
      this.fatchRecord();
    },
  },
};
</script>
<style lang="scss" scoped>
//
.pagination-outline {
  padding-top: 20px;
  box-sizing: border-box;
  text-align: center;
}
.newbtn {
  height: 32px;
  padding: 0 10px;
}
// .th-select input{
//     font-size: 14px;
//     font-weight: normal;
//     border: 0;
// }
.select-zoom {
  display: flex;
}
.select-item {
  margin-right: 20px;
  // width: 250px!important;
}
.select-zoom {
  padding: 20px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #e3e6e9;
}

// 持仓列表
// <div class="coin-zoom">
//     <span class="coin-zoom-icon">
//     <i class="el-icon-caret-bottom"></i>
//     </span>
//     <div class="coin-zoom-into">
//     <strong>BTC / USDT</strong>
//     <span>Buy</span>
//     </div>
// </div>
.coin-zoom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.coin-zoom-into {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.coin-zoom-into strong {
  font-size: 14px;
  line-height: 14px;
}
.coin-zoom-icon {
  width: 14px;
  height: 14px;
  transition: all ease 0.5s;
}
.detail-row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px;
  box-sizing: border-box;
}
.detail-col {
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  // align-items: flex-start;
  padding: 0 10px;
  box-sizing: border-box;
}
.span-h {
  font-size: 12px;
  color: #999;
  line-height: 20px;
  display: block;
  margin-bottom: 5px;
}
.span-p {
  display: block;
  font-size: 14px;
  color: #333;
  line-height: 20px;
  font-weight: bold;
}
.detail-row:nth-child(1) .detail-col {
  width: 25%;
}
.detail-row:nth-child(1) .detail-col:nth-child(4) {
  text-align: right;
}
.detail-row:nth-child(2) .detail-col {
  width: 42%;
}
.detail-row:nth-child(3) .detail-col {
  width: 42%;
}
.detail-row:nth-child(2) .detail-col:nth-child(3) {
  text-align: right;
}
.contactlist-item-detail {
  background: #f1f1f1;
  overflow: hidden;
  height: 0px;
  transition: all ease 0.5s;
}
.contactlist-item-detail-into {
  padding: 10px 0;
  box-sizing: border-box;
}
.contactlist-item-active .coin-zoom-icon {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg); /* IE 9 */
  -moz-transform: rotate(180deg); /* Firefox */
  -webkit-transform: rotate(180deg); /* Safari 和 Chrome */
  -o-transform: rotate(180deg); /* Opera */
}
.contactlist-item-into {
  cursor: pointer;
}
.contactlist-item-active .contactlist-item-detail {
  height: 150px;
}
.contactlist-item-active.contactlist-item-active1 .contactlist-item-detail {
  height: 220px;
}
.my-contract{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #e3e6e9;
}
.my-contract-item{
  // margin-right: 30px;
  text-align: center;
  width: 25%;
}
.my-contract-item strong{
  font-size: 18px;
  margin-bottom: 5px;
  display: block;
}
.my-contract-item div{
  font-size: 16px;
  color: #999;
}
</style>